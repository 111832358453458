var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    ref: "form",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "action": _vm.gateway,
      "method": "post",
      "target": "frame"
    }
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "payMethod",
      "value": "card"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "mid"
    },
    domProps: {
      "value": _vm.mid
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "goodsNm"
    },
    domProps: {
      "value": _vm.orderName
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "ordNo"
    },
    domProps: {
      "value": _vm.orderNo
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "goodsAmt"
    },
    domProps: {
      "value": _vm.paymentAmount
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "ordNm"
    },
    domProps: {
      "value": _vm.buyerName
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "ordTel"
    },
    domProps: {
      "value": _vm.buyerPhone
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "returnUrl"
    },
    domProps: {
      "value": _vm.returnUrl
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "notiUrl",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "userIp",
      "value": "127.0.0.1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "trxCd",
      "value": "0"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mbsUsrId",
      "value": "user1234"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mbsReserved",
      "value": "MallReserved"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "charSet",
      "value": "UTF-8"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "appMode",
      "value": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "ediDate"
    },
    domProps: {
      "value": _vm.ymd
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "encData"
    },
    domProps: {
      "value": _vm.hash
    }
  })]), _c('div', {
    ref: "progress",
    staticClass: "progress",
    staticStyle: {
      "display": "none"
    }
  }, [_c('div', {
    staticClass: "spinner"
  })]), _c('iframe', {
    ref: "frame",
    staticStyle: {
      "position": "fixed",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "id": "frame",
      "name": "frame",
      "src": "",
      "marginwidth": "0",
      "marginheight": "0",
      "frameborder": "no",
      "scrolling": "no"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }