<template>
    <div>
        <form ref="form" :action="gateway"  method="post" target="frame"  style="display: none;">
            <!-- 결제수단(card, vacnt, phone) -->
            <input type="text" name="payMethod" value="card">
            <!-- 결제방법선택 -->
            <input type="text" name="mid" :value="mid">
            <!-- 상품명 -->
            <input type="text" name="goodsNm" :value="orderName" >
            <!-- 주문번호 -->
            <input type="text" name="ordNo" :value="orderNo" >
            <!-- 결제금액 -->
            <input type="text" name="goodsAmt" :value="paymentAmount">
            <!-- 구매자명 -->
            <input type="text" name="ordNm" :value="buyerName">
            <!-- 구매자연락처 -->
            <input type="text" name="ordTel" :value="buyerPhone">
            <!-- 구매자이메일 -->
            <!-- <input type="text" name="ordEmail" :value="buyerEmail"> -->
            <!-- returnUrl -->
            <input type="text" name="returnUrl" :value="returnUrl">
            <!-- back으로 결제완료 데이터 받을 URL -->
            <input type="text" name="notiUrl" value="">

            <!-- 옵션 --> 
            <input type="hidden" name="userIp"	value="127.0.0.1">
            <input type="hidden" name="trxCd"	value="0">
                
            <input type="hidden" name="mbsUsrId" value="user1234">
            <input type="hidden" name="mbsReserved" value="MallReserved"><!-- 상점 예약필드 -->
            
            <!-- <input type="hidden" name="goodsSplAmt" value="0"> -->
            <!-- <input type="hidden" name="goodsVat" value="0"> -->
            <!-- <input type="hidden" name="goodsSvsAmt" value="0"> -->
            
            <input type="hidden" name="charSet" value="UTF-8">
            <input type="hidden" name="appMode" value="1">
            <!-- <input type="hidden" name="period" value="별도 제공기간없음"> -->
            
            <!-- 변경 불가능 -->
            <input type="hidden" name="ediDate" :value="ymd"><!-- 전문 생성일시 -->
            <input type="hidden" name="encData" :value="hash"><!-- 해쉬값 -->
        </form>
        
        <div ref="progress" class="progress" style="display: none;">
            <div class="spinner"></div>
        </div>

        <iframe id="frame" ref="frame" name="frame" style="position: fixed; width:100%; height:100%;" src="" marginwidth="0" marginheight="0" frameborder="no" scrolling="no"></iframe>
    </div>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import axios from 'axios';
import qs from "qs";

export default {
    data() {
        return {
            gateway: null,
            mid: null,
            
            orderNo: this.$route.query.orderNo,
            orderName: this.$route.query.orderName,
            paymentAmount: +(this.$route.query.paymentAmount || 0),
            buyerName: this.$route.query.buyerName,
            buyerPhone: this.$route.query.buyerPhone,
            // buyerEmail: this.$route.query.buyerEmail || "",

            ymd: "",
            hash: "",
        };
    },
    mounted() {
        this.init();
	    window.addEventListener("message", async(e) => {
            if(!e.data){
                window.parent?.paytusClose?.();
                window.opener?.paytusClose?.();
                window.close();
            }
            else{
                this.submit(e.data[1]);
            }
        }, false);

        this.auth();
    },
    methods: {
        init(){
            window.paytusComplete = (payload) => {
                if(!payload.resultCode){
                    window?.parent?.paytusComplete(payload);
                    window?.opener?.paytusComplete(payload);
                }
                this.submit(payload);
            };
        },
        async submit(payload){
            let { charSet, ediDate, goodsAmt, mbsReserved, mid, ordNo, payMethod, signData, tid, resultCode } = payload;
            
            if(resultCode === "0000"){
                let form = new FormData();
                form.append("tid", tid);
                form.append("ediDate", ediDate);
                form.append("mid", mid);
                form.append("goodsAmt", goodsAmt);
                form.append("charSet", charSet);
                form.append("signData", signData);
                form.append("encData", this.hash);

                try{
                    await axios({
                        method: "post",
                        url:"https://api2.dangunpay.co.kr/payment.do",
                        headers: { 
                            "Charset": "UTF-8", 
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: form
                    }).then(async(res)=>{
                        const response = await api.plugins.paytus.response(res.data);

                        this.$router.push(`/plugins/paytus/result?${qs.stringify(response)}`)
                    })
                } catch(e){
                    console.error(e);
                }
            }
        },
        async auth() {
            const { paymentAmount } = this.$data;
            const { gateway, mid } = await api.plugins.paytus.config.get();
            const { ymd, hash } = await api.plugins.paytus.generate({ paymentAmount });

            this.gateway = gateway;
            this.mid = mid;
            this.ymd = ymd;
            this.hash = hash;

            this.$nextTick(() => {
                var form = this.$refs.form;

                form.action = gateway;
                form.method = "post";
                form.submit();

            });
        },
    },
    computed: {
        returnUrl() {
            return `${window.location.protocol}//${window.location.host}/api/plugins/paytus/response`;
        },
        userAgent() {
            // return "WP"
            return (detect == "pc") ? "WP" : "WM";
        },
    },
};
</script>

<style scoped>
.progress{
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    background: rgba(0,0,0,0.54);
}
.progress .spinner {
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
}
@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
</style>